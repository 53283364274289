import React from "react"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import PageHeader from "./PageHeader"
import Header from "./Header"
import Footer from "./Footer"
import WhyChooseUs from "./WhyChooseUs"
import "../fontawesome"

export default function Layout({ children, title, hidePageHeader }) {
  const pageHeader = () => {
    if (!hidePageHeader) {
      return <PageHeader title={title} />
    }
  }

  return (
    <>
      <Helmet>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Heebo%3A700%7COpen+Sans&amp;display=swap"
        ></link>
      </Helmet>
      <Header />
      {pageHeader()}
      <main>{children}</main>
      <WhyChooseUs />
      <Footer />
      <div className="bg-dark p-3 text-light text-center">
        <p className="mb-0">
          Website by{" "}
          <a target="_blank" rel="noreferrer" href="https://sixvees.co.nz">
            Sixvees
          </a>
          .
        </p>
      </div>
    </>
  )
}
