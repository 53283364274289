import React from "react"

export default function WhyChooseUs() {
  return (
    <section id="offer" className="primary-cta bg-secondary text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="mb-5">Why choose us</h3>
            <ul className="fa-ul pl-4">
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                Convenient location with easy parking
              </li>
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                Nearby cafe with great coffee
              </li>
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                Staff with over 25 years of experience
              </li>
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                We work with most makes and models
              </li>
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                No job is too small
              </li>
              <li className="d-flex align-items-center mb-4">
                <span className="fa-li fa-2x text-primary">
                  <i className="fas fa-check-circle" />
                </span>
                Pick up and delivery service
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h3 className="mt-4 pt-3 pt-lg-0 mt-lg-0 mb-5">Book a service</h3>

            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              action="/thank-you"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="fName" className="sr-only">
                      First name
                    </label>
                    <span className="wpcf7-form-control-wrap first-name">
                      <input
                        type="text"
                        name="first-name"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="fName"
                        required
                        placeholder="First name"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="lName" className="sr-only">
                      Last name
                    </label>
                    <span className="wpcf7-form-control-wrap last-name">
                      <input
                        type="text"
                        name="last-name"
                        required
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="lName"
                        placeholder="Last name"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="email" className="sr-only">
                      Email address
                    </label>
                    <span className="wpcf7-form-control-wrap email">
                      <input
                        type="email"
                        name="email"
                        required
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                        id="email"
                        placeholder="Email address"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="phone" className="sr-only">
                      Phone number
                    </label>
                    <span className="wpcf7-form-control-wrap your-phone">
                      <input
                        type="text"
                        name="your-phone"
                        required
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="phone"
                        placeholder="Phone number"
                      />
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        name="your-message"
                        cols={40}
                        rows={5}
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                        id="message"
                        required
                        placeholder="Message"
                      />
                    </span>
                  </div>
                  <div className="form-group mb-0">
                    <input
                      type="submit"
                      defaultValue="Send"
                      className="wpcf7-form-control wpcf7-submit btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
