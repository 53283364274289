import React, { useState } from "react"
import { Link } from "gatsby"
import { Navbar, NavDropdown, Nav } from "react-bootstrap"

const Header = () => {
  const [burger, setBurger] = useState(false)
  return (
    <header className="site-header">
      <div className="container p-0">
        <Navbar expand="lg" variant="light">
          <Link
            className="d-inline-block brand h1 mb-0 text-white text-uppercase"
            to="/"
          >
            Service Centre
          </Link>
          <Navbar.Toggle
            className={
              burger
                ? "is-active hamburger hamburger--spring"
                : "hamburger hamburger--spring"
            }
            aria-controls="navbarSupportedContent"
            onClick={() => setBurger(!burger)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav id="menu-header" className="nav-primary ml-auto">
              <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/about">
                About
              </Link>
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                className="d-flex"
              >
                <Link className="dropdown-item" to="/chainsaws">
                  Chainsaws
                </Link>
                <Link className="dropdown-item" to="/lawnmowers">
                  Lawnmowers
                </Link>
                <Link className="dropdown-item" to="/line-trimmers">
                  Line Trimmers
                </Link>
                <Link className="dropdown-item" to="/water-blasters">
                  Water Blasters
                </Link>
                <Link className="dropdown-item" to="/generators-compressors">
                  Generators & Compressors
                </Link>
                <Link className="dropdown-item" to="/other-machinery">
                  Other Machinery
                </Link>
              </NavDropdown>
              <div className="dropdown d-lg-none">
                <Link className="dropdown-item" to="/chainsaws">
                  Chainsaws
                </Link>
                <Link className="dropdown-item" to="/lawnmowers">
                  Lawnmowers
                </Link>
                <Link className="dropdown-item" to="/line-trimmers">
                  Line Trimmers
                </Link>
                <Link className="dropdown-item" to="/water-blasters">
                  Water Blasters
                </Link>
                <Link className="dropdown-item" to="/generators-compressors">
                  Generators & Compressors
                </Link>
                <Link className="dropdown-item" to="/other-machinery">
                  Other Machinery
                </Link>
              </div>
              <Link className="nav-link" to="/contact">
                Contact us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}

export default Header
