import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer id="contact" className="site-footer bg-primary">
    <div className="container">
      <div className="row mt-n5 justify-content-between">
        <div className="col-md-6 col-lg-4 mt-5">
          <h5>Contact us</h5>
          <ul className="fa-ul mb-0">
            <li>
              <span className="fa-li">
                <i className="fas fa-map-marker-alt" />
              </span>
              159-167 Ngaumutawa Rd, Masterton 5840
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-phone fa-rotate-90" />
              </span>
              <a href="tel:063706926">06 370 6926</a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-envelope" />
              </span>
              <a href="mailto:machinery.masterton@mitre10.co.nz">
                machinery.masterton@mitre10.co.nz
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-lg-3 mt-5">
          <h5>Services</h5>
          <ul id="menu-footer-navigation" className="nav flex-column">
            <li
              id="menu-item-170"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-170"
            >
              <Link title="Chainsaws" to="/chainsaws" className="nav-link">
                Chainsaws
              </Link>
            </li>
            <li
              id="menu-item-121"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-121"
            >
              <Link title="Lawnmowers" to="/lawnmowers" className="nav-link">
                Lawnmowers
              </Link>
            </li>
            <li
              id="menu-item-169"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-169"
            >
              <Link
                title="Line Trimmers"
                to="/line-trimmers"
                className="nav-link"
              >
                Line Trimmers
              </Link>
            </li>
            <li
              id="menu-item-168"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-168"
            >
              <Link
                title="Water Blasters"
                to="/water-blasters"
                className="nav-link"
              >
                Water Blasters
              </Link>
            </li>
            <li
              id="menu-item-165"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-165"
            >
              <Link
                title="Generators & Compressors"
                to="/generators-compressors"
                className="nav-link"
              >
                Generators &amp; Compressors
              </Link>
            </li>
            <li
              id="menu-item-166"
              className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-166"
            >
              <Link
                title="Other machinery"
                to="/other-machinery"
                className="nav-link"
              >
                Other machinery
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 mt-5">
          <h5>Business hours</h5>
          <p>
            <strong>Monday:</strong> 8:30am to 3:30pm
            <br />
            <strong>Tuesday – Friday:</strong> 8:30am to 5:00pm
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
